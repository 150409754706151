.category_action_btn {
    display: flex;
    align-items: center;
    gap: 10px;
}
.category_action_btn .category_crud_action {
    border: 0;
    padding: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 30px;
    width: 30px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.5s;
    color: #fff;
}
.category_action_btn .category_crud_action:first-child {
    background-color: #10c469;
}
.category_action_btn .category_crud_action:last-child {
    background-color: #e43495;
}
.category-modal h4,
.category-modal lable {
    color: #e43495;
}
.add_category_data {
    display: flex;
    justify-content: flex-end;
}
.add_category_data button {
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    text-transform: uppercase;
    padding: 9px 15px;
    border-radius: 10px;
    background-color: #ff2424;
    transition: 0.5s;
    color: #fff;
}

select.category-status-dropdown {
    display: block;
    width: 100%;
    padding: 0.45rem 0.9rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--ct-input-color);
    background-color: var(--ct-input-bg);
    background-clip: padding-box;
    border: 1px solid var(--ct-input-border-color);
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    border-radius: 0.2rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
select.category-status-dropdown:focus-visible {
    outline: 0;
}
.add-category-from .category-post-btn {
    border: 0;
    padding: 0;
}

.category-post-btn {
    display: flex;
    justify-content: end;
    margin-top: 20px;
}
.modal-title {
    color: #e43495;
}
.category-post-btn button {
    border: 0;
    background-color: #e43495;
    color: #fff;
    width: 100px;
    height: 40px;
}
.category-post-btn.categories button {
    transition: 0.5s;
    opacity: 0.5;
}
.category-post-btn.categories .category-btn-active {
    opacity: 1;
}
.category-post-btn.delete-btn button[type='submit'] {
    transition: 0.5s;
    opacity: 0.5;
}
.category-post-btn.delete-btn button.category-btn-active {
    opacity: 1;
}
.category-post-btn.contents button {
    transition: 0.5s;
    opacity: 0.5;
}
.category-post-btn.contents button.category-btn-active {
    opacity: 1;
}
.category-modal .modal-dialog {
    max-width: 550px;
}
.add-category-from.post-category .col {
    margin-bottom: 25px;
}
.category-delete-modal .btn-close {
    display: none;
}
.category-post-btn.delete-btn {
    gap: 15px;
}
.category-post-btn.delete-btn button:first-child {
    background-color: #ff2424;
}
.category-post-btn.delete-btn button:last-child {
    background-color: #04c504;
}
.loading-spinner {
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: middle;
}
.content_data_table tbody tr td:nth-child(2) {
    max-width: 400px;
}
.add_category_data.content-data-btn {
    margin-bottom: 20px;
}
.content-search-data input {
    max-width: 100%;
    /* min-width: 100%; */
    padding: 12px 10px;
}

.content-search-data select {
    margin-left: 10px;
    padding: 12px 10px;
}

.content-search-data {
    margin: 35px 0 10px;
}
.css-nvf14r-ToastContainer {
    position: relative;
    z-index: 999999 !important;
}
.react-toast-notifications__container.css-jfeqr5 {
    z-index: 1002 !important;
}
.logout-list-inline-item {
    color: #98a6ad;
    font-size: 20px;
}
.text-muted.after-logout {
    display: flex;
    align-items: center;
    justify-content: center;
}
.record-not-found {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px;
    max-width: 400px;
    margin: 50px auto 30px auto;
    background-color: #71b6f92e;
    border-radius: 20px;
}
.record-not-found h1 {
    color: #000;
    font-size: 25px;
    text-align: center;
}
.auth-logo img {
    width: 150px;
}
.auth-logo {
    width: fit-content;
    margin: auto;
}
.content-loader-spinner {
    width: 4rem;
    height: 4rem;
    margin: 70px auto 50px auto;
    display: block;
    border-width: 0.3em;
}
label.custom-checkbox {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    width: fit-content;
    user-select: none;
}
label.custom-checkbox p {
    margin: 0;
    line-height: normal;
}
.upload_img button.btn.btn-primary {
    display: block;
    margin-top: 10px;
}
.image_popup .modal-body {
    text-align: center;
}
.image_popup .modal-body img {
    margin: 0;
}
.browse_capture_file .capture {
    color: #000;
    background-color: #ececec;
    border: 1px solid #9a9595;
    border-right: 0;
    max-width: 130px;
    min-width: 130px;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0;
}
.browse_capture_file .browse {
    color: #9a9595;
    width: 100%;
    background-color: #ffff;
    border: 1px solid #9a9595;
    text-align: left;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0;
}
.browse_capture_file {
    display: flex;
    margin-bottom: 0.5rem;
}
.browse_capture_file .capture:focus {
    box-shadow: none;
}
.browse_capture_file .browse:focus {
    box-shadow: none;
}
.or_label {
    width: 100%;
    text-align: center;
    margin: 0 0 0.5rem 0;
}
.account-pages:has(.customer_auth_form).account-pages .row .col-xl-4 {
    flex: 0 0 60%;
    width: 60%;
}
.account-pages .customer_auth_form .customer_info_submit {
    border-color: #e43495;
}
.account-pages .customer_auth_form .customer_info_submit:hover {
    border-color: #90d9f8;
}
.capture_img_view {
    max-width: 200px;
    min-width: 200px;
    height: 172px;
    margin-bottom: 15px;
    margin-top: 25px;
}
.capture_img_view img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-top: 0 !important;
    border-radius: 10px;
}
.phone_field {
    position: relative;
}
.phone_field .dropdown-wrapper {
    border: 1px solid var(--ct-input-border-color);
    border-radius: 10px;
    position: absolute;
    left: 46px;
    right: 0;
    background-color: #fff;
    z-index: 2;
    overflow: hidden;
    max-width: 303px;
    box-shadow: 0px 0px 20px 0px #b8b8b8;
}
.phone_field .dropdown-wrapper p {
    margin-bottom: 0;
}
.phone_field .dropdown-wrapper .first_last_name {
    display: flex;
    gap: 5px;
    padding: 10px 0;
}
.phone_field .main_records {
    border-bottom: 1px solid var(--ct-input-border-color);
    padding: 5px 20px;
}
.main_records:hover {
    background-color: #e43495;
    transition: 0.5s;
    color: #fff;
    cursor: pointer;
}

.main_records:last-child {
    border: none;
}
@media screen and (max-width: 1023px) and (min-width: 768px) {
    .capture_img_view {
        max-width: 170px;
        min-width: 170px;
        height: 142px;
    }
    .account-pages:has(.customer_auth_form).account-pages .row .col-xl-4 {
        flex: 0 0 80%;
        width: 80%;
    }
}

@media screen and (max-width: 767px) and (min-width: 200px) {
    .content-search-data .search_filter_section {
        flex-wrap: wrap;
        gap: 15px;
    }
    .content-search-data .search_filter_section select {
        width: 100% !important;
    }
    .content-search-data input[type='search'] {
        max-width: 100%;
        min-width: 100%;
    }
    .capture_img_view {
        max-width: 150px;
        min-width: 150px;
        height: 122px;
    }
    .account-pages .customer_auth_form .row .col {
        flex: 0 0 100%;
        width: 100%;
    }
    .account-pages:has(.customer_auth_form).account-pages .row .col-xl-4 {
        flex: 0 0 100%;
        width: 100%;
    }
    .browse_capture_file .capture {
        font-size: 13px;
    }
    .browse_capture_file .browse {
        font-size: 13px;
    }
    .account-pages .customer_auth_form .customer_info_submit {
        width: 100%;
    }
}
