//
// react-typeahead.scss
//

.rbt .rbt-input-main::-ms-clear {
    display: none;
}

/**
 * Menu
 */
.rbt-menu {
    margin-bottom: 2px;
}
.rbt-menu > .dropdown-item {
    overflow: hidden;
    text-overflow: ellipsis;
}
.rbt-menu > .dropdown-item:focus {
    outline: none;
}
.rbt-menu-pagination-option {
    text-align: center;
}

/**
 * Multi-select Input
 */
.rbt-input-multi {
    cursor: text;
    overflow: hidden;
    position: relative;
}
.rbt-input-multi.focus {
    border-color: $border-color;
    box-shadow: $box-shadow-sm;
    color: $body-color;
    outline: 0;
}
.rbt-input-multi.form-control {
    height: auto;
}
.rbt-input-multi.form-control[disabled] {
    background-color: #{map-get($grays, "300")};
    opacity: 1;
}
.rbt-input-multi.is-invalid.focus {
    border-color: $danger;
    box-shadow: 0 0 0 0.2rem rgba($danger, 0.25);
}
.rbt-input-multi.is-valid.focus {
    border-color: $success;
    box-shadow: 0 0 0 0.2rem rgba($success, 0.25);
}
.rbt-input-multi input::-moz-placeholder {
    color: #{map-get($grays, "700")};
    opacity: 1;
}
.rbt-input-multi input:-ms-input-placeholder {
    color: #{map-get($grays, "700")};
}
.rbt-input-multi input::-webkit-input-placeholder {
    color: #{map-get($grays, "700")};
}
.rbt-input-multi .rbt-input-wrapper {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -4px;
    margin-top: -1px;
    overflow: hidden;
}
.rbt-input-multi .rbt-input-main {
    margin: 1px 0 4px;
}

/**
 * Close Button
 */
.rbt-close {
    z-index: 1;
}
.rbt-close-lg {
    font-size: 24px;
}

/**
 * Token
 */
.rbt-token {
    background-color: #{map-get($grays, "300")};
    border: 0;
    border-radius: 0.25rem;
    color: $primary;
    display: inline-flex;
    line-height: 1em;
    margin: 1px 3px 2px 0;
}
.rbt-token .rbt-token-label {
    padding: 4px 7px;
}
.rbt-token-disabled {
    background-color: #{map-get($grays, "900")};
    color: #{map-get($grays, "700")};
    pointer-events: none;
}
.rbt-token-removeable {
    cursor: pointer;
}
.rbt-token-active {
    background-color: $primary;
    color: $white;
    outline: none;
    text-decoration: none;
}
.rbt-token .rbt-token-remove-button {
    background-image: none;
    box-shadow: none;
    color: inherit;
    display: flex;
    justify-content: center;
    font-size: inherit;
    font-weight: normal;
    opacity: 1;
    outline: none;
    padding: 3px 7px;
    padding-left: 0;
    text-shadow: none;
}
.rbt-token .rbt-token-remove-button .rbt-close-content {
    display: block;
}

/**
 * Loader + CloseButton container
 */
.rbt-aux {
    align-items: center;
    display: flex;
    bottom: 0;
    justify-content: center;
    pointer-events: none;
    /* Don't block clicks on the input */
    position: absolute;
    right: 0;
    top: 0;
    width: 34px;
}
.rbt-aux-lg {
    width: 46px;
}
.rbt-aux .rbt-close {
    margin-top: -4px;
    pointer-events: auto;
    /* Override pointer-events: none; above */
}

.has-aux .form-control {
    padding-right: 32px;
}
.has-aux .form-control.is-valid,
.has-aux .form-control.is-invalid {
    background-position: right 2rem center;
    padding-right: 64px;
}

.rbt-highlight-text {
    background-color: inherit;
    color: inherit;
    font-weight: bold;
    padding: 0;
}

/**
 * Input Groups
 */
.input-group > .rbt {
    flex: 1;
}
.input-group > .rbt .rbt-input-hint,
.input-group > .rbt .rbt-aux {
    z-index: 5;
}
.input-group > .rbt:not(:first-child) .form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.input-group > .rbt:not(:last-child) .form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

body[data-layout-color="dark"] {
    .rbt-input-multi.form-control[disabled] {
        background-color: #{map-get($dark-grays, "300")};
    }
    .rbt-input-multi input::-moz-placeholder {
        color: #{map-get($dark-grays, "700")};
    }
    .rbt-input-multi input:-ms-input-placeholder {
        color: #{map-get($dark-grays, "700")};
    }
    .rbt-input-multi input::-webkit-input-placeholder {
        color: #{map-get($dark-grays, "700")};
    }
    .rbt-token {
        background-color: #{map-get($dark-grays, "300")};
    }
    .rbt-token-disabled {
        background-color: #{map-get($dark-grays, "900")};
    }
}
